/* eslint-disable complexity */
const Appearance = require('@mshops-web-core/appearance').default;

const { COMPONENTS_STATICS_TOP } = require('../../../utils/constants/common');
const renderDefaultState = require('./defaultState');
const types = require('./types');
const removeUnnecessaryChildren = require('./utils');

const renderReducer = (
  state = renderDefaultState,
  action,
) => {
  const { appearance } = state;
  const appearanceCopy = new Appearance(appearance.getTree());
  switch (action.type) {
    case types.UPDATE_LAYOUT: {
      const { layout } = action.payload;
      return {
        ...state,
        appearance: new Appearance(layout),
        action: action.type,
        sender: action.sender,
      };
    }
    case types.CHANGE_DEVICE: {
      const { device } = action.payload;
      return {
        ...state,
        appearance: new Appearance(appearance.getTree()),
        device,
        updateDevice: true,
        action: action.type,
        sender: action.sender,
      };
    }
    case types.CHANGE_OSNAME: {
      const { osName } = action.payload;
      return {
        ...state,
        appearance: new Appearance(appearance.getTree()),
        osName,
        action: action.type,
        sender: action.sender,
      };
    }
    case types.SHOULD_UPDATE_DEVICE: {
      return {
        ...state,
        updateDevice: action.payload,
      };
    }
    case types.REORDER_LAYOUT_COMPONENT: {
      const { componentId, position } = action.payload;

      const {
        appearanceChildrenRemoved,
        elementsRemoved,
      } = removeUnnecessaryChildren(new Appearance(appearance.getTree()));

      const appearanceCopyMove = appearanceChildrenRemoved;

      const moved = appearanceCopyMove.moveLayoutComponent(componentId, position);
      if (moved) {
        appearanceCopyMove.updateComponentProperties(componentId, { scrollToMe: true });

        if (elementsRemoved) {
          elementsRemoved.forEach(element => {
            appearanceCopyMove.layout.children.push(element);
          });
        }

        return {
          ...state,
          appearance: new Appearance(appearanceCopyMove.getTree()),
          action: action.type,
          sender: action.sender,
        };
      }

      return state;
    }
    case types.MOVE_COMPONENT_UP: {
      const { component } = action.payload;
      const { id: prevSiblingId } = appearanceCopy.layout.children[
        appearanceCopy.getLayoutComponentIndex(component) - 1
      ] || {};
      const moved = appearanceCopy.moveLayoutComponentUp(component);
      if (moved) {
        appearanceCopy.updateComponentProperties(
          component,
          { animationUp: false, bringFront: false, scrollToMe: true },
        );
        appearanceCopy.updateComponentProperties(prevSiblingId, { animationDown: false, pullBack: false });
        return {
          ...state,
          appearance: new Appearance(appearanceCopy.getTree()),
          action: action.type,
          sender: action.sender,
        };
      }
      return state;
    }
    case types.MOVE_COMPONENT_DOWN: {
      const { component } = action.payload;
      const { id: nextSiblingId } = appearanceCopy.layout.children[
        appearanceCopy.getLayoutComponentIndex(component) + 1
      ] || {};
      const moved = appearanceCopy.moveLayoutComponentDown(component);
      if (moved) {
        appearanceCopy.updateComponentProperties(
          component,
          { animationDown: false, bringFront: false, scrollToMe: true },
        );
        appearanceCopy.updateComponentProperties(nextSiblingId, { animationUp: false, pullBack: false });
        return {
          ...state,
          appearance: new Appearance(appearanceCopy.getTree()),
          action: action.type,
          sender: action.sender,
        };
      }
      return state;
    }
    case types.MOVE_TO_COMPONENT: {
      appearanceCopy.updateComponentProperties(action.payload.componentId, { scrollToMe: true });
      return {
        ...state,
        appearance: new Appearance(appearanceCopy.getTree()),
        action: action.type,
        sender: action.sender,
      };
    }
    case types.RESET_LAYOUT: {
      return {
        ...state,
        appearance: new Appearance(state.initial.getTree()),
        action: action.type,
        sender: action.sender,
      };
    }
    case types.SAVE_LAYOUT: {
      return {
        ...state,
        initial: new Appearance(state.appearance.getTree()),
        action: action.type,
        sender: action.sender,
      };
    }
    case types.TRIGGER_ANIMATION_UP: {
      const { component } = action.payload;
      const { id: prevSiblingId } = appearanceCopy.layout.children[
        appearanceCopy.getLayoutComponentIndex(component) - 1
      ];
      appearanceCopy.updateComponentProperties(component, { animationUp: true, bringFront: true });
      appearanceCopy.updateComponentProperties(prevSiblingId, { animationDown: true, pullBack: true });
      return {
        ...state,
        appearance: new Appearance(appearanceCopy.getTree()),
        action: action.type,
        sender: action.sender,
      };
    }
    case types.TRIGGER_ANIMATION_DOWN: {
      const { component } = action.payload;
      const { id: nextSiblingId } = appearanceCopy.layout.children[
        appearanceCopy.getLayoutComponentIndex(component) + 1
      ];
      appearanceCopy.updateComponentProperties(component, { animationDown: true, bringFront: true });
      appearanceCopy.updateComponentProperties(nextSiblingId, { animationUp: true, pullBack: true });
      return {
        ...state,
        appearance: new Appearance(appearanceCopy.getTree()),
        action: action.type,
        sender: action.sender,
      };
    }
    case types.UPDATE_COMPONENT_PROPS: {
      const { component, props } = action.payload;
      appearanceCopy.updateComponentProperties(component, props);
      return {
        ...state,
        appearance: new Appearance(appearanceCopy.getTree()),
        action: action.type,
        sender: action.sender,
      };
    }
    case types.SET_COMPONENT_HIDDEN: {
      const { component, hidden } = action.payload;
      const changed = appearanceCopy.setLayoutComponentHidden(component, hidden);
      if (changed) {
        return {
          ...state,
          appearance: new Appearance(appearanceCopy.getTree()),
          action: action.type,
          sender: action.sender,
        };
      }
      return state;
    }
    case types.REMOVE_LAYOUT_COMPONENT: {
      const { component } = action.payload;
      const filteredChildren = appearanceCopy.layout.children.filter(child => child.id !== component);
      appearanceCopy.replaceLayoutChildren(filteredChildren);
      return {
        ...state,
        appearance: new Appearance(appearanceCopy.getTree()),
        action: action.type,
        sender: action.sender,
      };
    }
    case types.ADD_LAYOUT_COMPONENT: {
      const { componentPayload, position } = action.payload;

      const appearanceChildren = appearanceCopy.layout.children;

      appearanceChildren.splice(position, 0, componentPayload);
      return {
        ...state,
        appearance: new Appearance(appearanceCopy.getTree()),
        action: action.type,
        sender: action.sender,
      };
    }
    case types.LAYOUT_REQUEST: {
      return {
        ...state,
        fetching: true,
        action: action.type,
        sender: action.sender,
      };
    }
    case types.LAYOUT_REQUEST_SUCCESS_FULL: {
      const { appearanceResponse, device } = action.payload;

      const appearanceInstance = new Appearance(appearanceResponse ?? {});

      const numberStaticComponentsTop = COMPONENTS_STATICS_TOP.reduce((acc, componentId) => {
        const component = appearanceInstance?.getComponentById?.(componentId);

        if (component) {
          return acc + 1;
        }

        return acc;
      }, 0);

      return {
        ...state,
        appearance: new Appearance(appearanceResponse),
        initial: new Appearance(appearanceResponse),
        fetching: false,
        device,
        action: action.type,
        sender: action.sender,
        numberStaticComponentsTop,
      };
    }
    case types.LAYOUT_REQUEST_SUCCESS_PARTIAL: {
      const { appearanceResponse, device, componentId } = action.payload;
      const updatedAppearance = new Appearance(appearanceResponse);

      const updatedComponent = updatedAppearance.getComponentById(componentId);

      if (!updatedComponent) {
        return {
          ...state,
          fetching: false,
          action: action.type,
          sender: action.sender,
        };
      }

      appearanceCopy.updateComponentProperties(componentId, updatedComponent.properties);

      return {
        ...state,
        appearance: new Appearance(appearanceCopy.getTree()),
        fetching: false,
        device,
        action: action.type,
        sender: action.sender,
      };
    }
    case types.LAYOUT_REQUEST_ERROR: {
      return {
        ...state,
        fetching: false,
        action: action.type,
        sender: action.sender,
      };
    }
    case types.SECTION_EDITING: {
      const { component } = action?.payload;

      return {
        ...state,
        editingComponent: component,
      };
    }
    case types.SET_LOADING: {
      const { value } = action?.payload;

      return {
        ...state,
        fetching: value,
      };
    }
    case types.SET_RICH_TEXT_VALUE: {
      return {
        ...state,
        richTextValueSetted: action?.payload,
      };
    }
    case types.SET_CCP_TEMPLATE: {
      return {
        ...state,
        ccpTemplate: action?.payload,
      };
    }
    case types.SHOW_MENU_NAVIGATION: {
      return {
        ...state,
        isShowMenuNavigation: action?.payload,
      };
    }
    case types.CURRENT_CATEGORY_SELECTED: {
      return {
        ...state,
        currentCategorySelected: action?.payload,
      };
    }
    default:
      return state;
  }
};

module.exports = renderReducer;
