import ClientService from '../../services/client';

const updateCartFreya = async (isWebview, apiBasePath) => {
  try {
    if (isWebview && window?.MobileWebKit) {
      window.MobileWebKit.executeNative({
        method: 'refresh_cart',
        args: {},
      });
      return;
    }
    if (window?.freya) {
      window.freya.emit('cart:refresh');
    }
  } catch (error) {
    try {
      const RestClientService = new ClientService(apiBasePath);
      await RestClientService.post(
        '/error-report',
        { data: { message: JSON.stringify(error) } },
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error updating cart', err);
    }
  }
};

export default updateCartFreya;
