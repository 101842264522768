import React from 'react';
import PropTypes from 'prop-types';
import { useRenderContext } from '../../pages/home/context';

export const Wrapper = (RecommendationsBase) => {
  const ProductsGalleryBase = (props) => {
    const { currency, restClient, theme, features } = useRenderContext();
    const { i18n } = props;
    const shippingTitle = i18n.gettext('Envío gratis');
    const ftNewComponentsV2 = features?.includes('ft-new-components-v2');

    return (
      <div className="mshops-recommendations-wrapper">
        <RecommendationsBase
          {...props}
          metadata={currency}
          restClient={restClient}
          type="recommendations"
          shippingTitle={shippingTitle}
          layoutTheme={theme}
          ftNewComponentsV2={ftNewComponentsV2}
        />
      </div>
    );
  };

  ProductsGalleryBase.propTypes = {
    i18n: PropTypes.shape({
      gettext: PropTypes.func,
    }).isRequired,
  };

  return ProductsGalleryBase;
};
