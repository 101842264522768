import buildFluxContext from '@mshops-web-components/flux-context';

import freeShippingDefaultState from './defaultState';
import freeShippingReducer from './reducers';
import FreeShippingActions from './actions';

const {
  FluxProvider: FreeShippingProvider,
  useFluxContext: useFreeShippingContext,
  useFluxDispatcher: useFreeShippingDispatcher,
} = buildFluxContext(freeShippingDefaultState, freeShippingReducer, FreeShippingActions);

export {
  FreeShippingProvider,
  useFreeShippingContext,
  useFreeShippingDispatcher,
};
