const React = require('react');
const PropTypes = require('prop-types');

const Edit = ({
  width = 18,
  height = 18,
  color = '#3483FA',
  className = '',
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill={color} fillRule="nonzero" d="M13.865 0l-2.083 2.083 4.135 4.135L18 4.135 13.865 0zM11.09 2.774l-9.28 9.282 4.134 4.135 9.282-9.281-4.136-4.136zM1.178 12.808L0 18l5.192-1.178-4.014-4.014z" />
  </svg>
);

Edit.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

module.exports = Edit;
