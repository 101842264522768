export const CAROUSEL_CONFIG_BY_DEVICE = {
  CAROUSEL: {
    desktop: {
      slidesPerView: 5,
      thresholdSlide: 0,
      spacing: 12,
      arrows: true,
    },
    mobile: {
      slidesPerView: 2,
      thresholdSlide: 20,
      spacing: 0,
      arrows: false,
    },
  },
};

export const FORMATS_SEPARATOR = {
  thousand: {
    MLM: ',',
    default: '.',
  },
  decimal: {
    MLM: '.',
    default: ',',
  },
};
