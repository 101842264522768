import React, { useRef } from 'react';
import parse from 'html-react-parser';
import CustomScript from '../appearance/customScript';
import CustomAddToCart from '../appearance/customAddToCart';

/**
 * Hook: useCustomizationParser
 *
 * Parses and processes the `customization.html` content, replacing specific DOM nodes with custom React components
 * (`CustomScript` and `CustomAddToCart`) based on their attributes.
 *
 * @param {Object} customization - Customization data.
 * @param {string} customization.html - The raw HTML string to parse and process.
 * @param {Object} customization.javascript - A mapping of local sources of JavaScript files to CDN sources.
 * @param {Array<Function>} scripts - An array to store script initialization functions.
 * @param {Function} getItem - A function to retrieve an item by ID.
 * @param {boolean} withCardAction - Indicates whether card actions should be included on click in Add To Cart.
 * @param {Function} trackingInfo - A function to retrieve tracking information for an item.
 * @param {Object} props - Additional props passed to components rendered by the parser.
 *
 * @returns {Object} - An object containing:
 *   @property {Object} ref - A `useRef` object containing the parsed HTML.
 *   @property {React.Element} html - The current parsed HTML content (as a string or React element).
 */
const useCustomizationParser = (
  customization,
  scripts,
  getItem = () => {},
  withCardAction = false,
  trackingInfo = () => {},
  props = {},
) => {
  const domNodes = useRef(null);

  domNodes.current = parse(customization.html, {
    replace(domNode) {
      if (domNode.name === 'script' && domNode.attribs?.src) {
        return (
          <CustomScript
            attribs={domNode.attribs}
            sources={customization.javascript}
            scripts={scripts}
          />
        );
      }

      if (domNode.attribs?.['data-replacement'] === 'add-to-cart') {
        return (
          <CustomAddToCart
            domNode={domNode}
            getItem={getItem}
            withCardAction={withCardAction}
            trackingInfo={trackingInfo}
            parentProps={props}
          />
        );
      }

      return domNode;
    },
  });

  return { domNodes: domNodes.current };
};

export default useCustomizationParser;
