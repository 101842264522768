import React from 'react';
import PropTypes from 'prop-types';
import CardActionCustomWrapper from '../../components/CardActionCustomWrapper';

/**
 * Renders a `CardActionCustomWrapper` for a specific item, based on the DOM node's attributes
 * and additional props. If the component is in an editable state or the item is not found,
 * it returns an empty fragment.
 *
 * Features:
 * - **Item Retrieval:** Extracts the `itemId` from the DOM node attributes and fetches the item using `getItem`.
 * - **Editable Mode Handling:** Prevents rendering when the component is in an editable state (`Editable`).
 * - **Custom Card Action:** Passes the item and additional props to the `CardActionCustomWrapper` for rendering.
 * - **Tracking Data:** Adds tracking information to the rendered component using the `trackingInfo` function.
 *
 * Props:
 * @param {Object} props - Props for the component.
 * @param {Object} props.domNode - The DOM node containing the attributes.
 * @param {Function} props.getItem - Function to retrieve the item by its `itemId`.
 * @param {Boolean} props.withCardAction - Flag to indicate whether to include card actions.
 * @param {Function} props.trackingInfo - Function to generate tracking data for the item.
 * @param {Object} props.parentProps - Parent properties, including `i18n` and component-specific settings.
 *
 * Returns:
 * @returns {React.JSX.Element} A `CardActionCustomWrapper` component or an empty fragment.
 */
const CustomAddToCart = (props) => {
  const { domNode, getItem, withCardAction, trackingInfo, parentProps } = props;
  const isEditable = parentProps.componentProperties?.includes('Editable');
  const itemId = domNode.attribs?.['data-id'];
  const item = getItem(itemId);

  if (!item || isEditable) {
    return null;
  }

  return (
    <CardActionCustomWrapper
      key={domNode.attribs?.id}
      item={{ ...item, with_card_action: withCardAction }}
      i18n={parentProps.i18n}
      trackingData={trackingInfo(item)}
      {...parentProps}
    />
  );
};

CustomAddToCart.propTypes = {
  domNode: PropTypes.shape({
    name: PropTypes.string.isRequired,
    attribs: PropTypes.object,
  }).isRequired,
  getItem: PropTypes.func.isRequired,
  withCardAction: PropTypes.bool,
  trackingInfo: PropTypes.func,
  parentProps: PropTypes.shape({
    componentProperties: PropTypes.string,
    i18n: PropTypes.object,
  }).isRequired,
};

export default CustomAddToCart;
