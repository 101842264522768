const Eye = require('./Eye');
const EyeOff = require('./EyeOff');
const Edit = require('./Edit');
const Picture = require('./Picture');
const Slide = require('./Slide');
const Shape = require('./Shape');
const Bag = require('./Bag');

module.exports = {
  Eye,
  EyeOff,
  Edit,
  Picture,
  Slide,
  Shape,
  Bag,
};
