import { useRef, useState } from 'react';

const useScriptInitializer = () => {
  const scripts = useRef([]);
  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  const executeScripts = () => {
    scripts.current.forEach((script) => {
      try {
        script();
      } catch (e) {
        console.log(`CUSTOM-HTML SCRIPT ERROR\n\n%c${e.message}`, 'color: red;');
      }
    });
  };

  return { scripts: scripts.current, executeScripts, scriptsLoaded, setScriptsLoaded };
};

export default useScriptInitializer;
