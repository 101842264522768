const ClientService = require('../../../../../services/client');

const types = require('./types');

module.exports = class FreeShippingActions {
  constructor(dispatcher, state) {
    this.dispatcher = dispatcher;
    this.restClient = new ClientService(state.apiBasePath);
  }

  static initialize(dispatcher, state) {
    if (!this.singleton) {
      this.singleton = new FreeShippingActions(dispatcher, state);
    }

    return this.singleton;
  }

  setFreeShippingBar(freeShippingBar) {
    this.dispatcher({
      type: types.SET_FREE_SHIPPING,
      payload: { freeShippingBar },
    });
  }

  updateFreeShippingBar(userZipCode, shopType) {
    this.dispatcher({
      type: types.SET_CART_FETCHING,
      payload: true,
    });

    return this.restClient.post(
      '/free-shipping-bar',
      { data: { userZipCode, shopType } },
      6000,
    ).then(({ status, data: freeShippingBar }) => {
      if (status === 200) {
        this.setFreeShippingBar(freeShippingBar);
      }
    }).finally(() => {
      this.dispatcher({
        type: types.SET_CART_FETCHING,
        payload: false,
      });
    });
  }
};
