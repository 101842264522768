const buildFluxContext = require('@mshops-web-components/flux-context').default;

const cartDefaultState = require('./defaultState');
const cartReducer = require('./reducers');
const CartActions = require('./actions');

const {
  FluxProvider: CartProvider,
  useFluxContext: useCartContext,
  useFluxDispatcher: useCartDispatcher,
} = buildFluxContext(cartDefaultState, cartReducer, CartActions);

module.exports = {
  CartProvider,
  useCartContext,
  useCartDispatcher,
};
