const tracks = {
  newsletterView: {
    melidata_event: {
      type: 'view',
      path: '/visitor_registration/form',
      event_data: {},
    },
    analytics_event: {
      path: '/VISITOR_REGISTRATION/FORM/',
      type: 'view',
      page: '/VISITOR_REGISTRATION/',
    },
  },
  newsletterCongratsView: {
    melidata_event: {
      type: 'view',
      path: '/visitor_registration/congrats',
      event_data: {},
    },
    analytics_event: {
      path: '/VISITOR_REGISTRATION/CONGRATS/',
      type: 'view',
      page: '/VISITOR_REGISTRATION/',
    },
  },
  productsComponentsView: {
    melidata_event: {
      type: 'view',
      path: '/home',
      event_data: {},
    },
  },
  newsletterSubscription: {
    melidata_event: {
      path: '/visitor_registration/form/subscribe_registration',
      event_data: {
        event_category: 'automatization',
        event_action: 'subscribe_registration',
        event_label: 'visitor_registration',
      },
    },
    analytics_event: {
      page: '/VISITOR_REGISTRATION/FORM/',
      action: 'SUBSCRIBE_REGISTRATION',
      category: 'AUTOMATIZATION',
      label: 'VISITOR_REGISTRATION',
    },
  },
  productsComponents: {
    melidata_event: {
      path: '/home/mouse_enter_component',
      event_data: {},
    },
  },
  couponsModalDismiss: {
    melidata_event: {
      type: 'event',
      path: '/eshops/coupons/modal_dismiss',
      event_data: {},
    },
  },
  couponsModalOpen: {
    melidata_event: {
      type: 'view',
      path: '/eshops/coupons/modal_new_follower',
      event_data: {},
    },
  },
  couponsFollow: {
    melidata_event: {
      type: 'event',
      path: '/eshops/follow/coupons',
      event_data: {
        origin: 'modal',
        section: 'home',
        action: 'do_follow',
      },
    },
  },
  pillsLanding: {
    melidata_event: {
      type: 'event',
      path: '/eshops/landing/pills',
      event_data: {},
    },
  },
};

module.exports = tracks;
