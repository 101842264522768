import { useRenderContext } from '../context';
import { isFirstComponent } from '../components/HomeLayout/utils';
import { THEMES_WITH_TRANSPARENT_HEADER, LEGEND_THEME } from '../../../utils/constants/themeConfig';
import useLayoutTheme from './layoutTheme';

const isLegendTheme = () => {
  const layoutTheme = useLayoutTheme();
  return layoutTheme === LEGEND_THEME;
};

const getComponentsToSearch = () => (isLegendTheme() ? ['Slider', 'BannerSplinter'] : ['Slider']);

const getLegacyTransparency = appearance => {
  const layoutTheme = useLayoutTheme();
  const themeUsesTransparency = THEMES_WITH_TRANSPARENT_HEADER.includes(layoutTheme);
  const opacity = appearance.getComponentProperty('Header', 'opacity');

  const isTransparent = layoutTheme === LEGEND_THEME || ((opacity !== undefined) && (opacity !== 1));
  return isTransparent && themeUsesTransparency;
};

const getNewTransparency = customStyles => {
  const opacity = customStyles?.opacity;
  const isTransparentNew = opacity !== undefined && opacity !== 1;

  return isTransparentNew;
};

const useHomeTransparency = ({ isCustomHTML } = { isCustomHTML: false }) => {
  const { appearance } = useRenderContext();

  const customStyles = appearance.getComponentProperty('Header', 'custom_styles');

  const componentsToSearch = getComponentsToSearch();
  const isFirstElement = isFirstComponent(componentsToSearch, appearance);

  if (!isFirstElement) {
    return false;
  }

  if (!customStyles || isCustomHTML) {
    return getLegacyTransparency(appearance);
  }

  return getNewTransparency(customStyles);
};

export default useHomeTransparency;
