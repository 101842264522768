const buildFluxContext = require('@mshops-web-components/flux-context').default;

const defaultState = require('./defaultState');
const renderReducer = require('./reducers');
const RenderActions = require('./actions');

const {
  FluxProvider: RenderProvider,
  useFluxContext: useRenderContext,
  useFluxDispatcher: useRenderDispatcher,
} = buildFluxContext(defaultState, renderReducer, RenderActions);

module.exports = {
  RenderProvider,
  useRenderContext,
  useRenderDispatcher,
};
