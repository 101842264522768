import React from 'react';
import { Card, PolycardContext } from '@polycard/card';

import { useRenderContext } from '../../pages/home/context';
import { FORMATS_SEPARATOR } from '../polycardCarousel/utils';
import { WITH_POLYCARD_GALLERY } from '../../utils/constants/shop/types';

const withPolycardGallery = (Component) => {
  const PolycardGallery = props => {
    const { categories } = props;
    const { shopType, device, siteId } = useRenderContext();
    const { polycard_context: polycardContext, polycards } = categories?.[0] || {};

    const formats = {
      thousandSeparator: FORMATS_SEPARATOR.thousand[siteId] || FORMATS_SEPARATOR.thousand.default,
      decimalSeparator: FORMATS_SEPARATOR.decimal[siteId] || FORMATS_SEPARATOR.decimal.default,
    };

    const isPolycard = polycardContext && polycards?.length > 0;

    const withPolycard = WITH_POLYCARD_GALLERY.includes(shopType) && isPolycard;

    if (!withPolycard) {
      return <Component {...props} />;
    }

    const PolycardItem = propsPolycard => (
      <li>
        <Card {...propsPolycard} />
      </li>
    );

    return (
      <PolycardContext
        contextValue={
          {
            ...polycardContext,
            type: 'grid-card',
            target: '_self',
          }
        }
        deviceType={device}
        withoutIcons
        {...formats}
      >
        <Component
          ComponentCard={PolycardItem}
          isPolycard={withPolycard}
          key={`gallery-polycard-${props?.id}`}
          {...props}
        />
      </PolycardContext>
    );
  };

  return PolycardGallery;
};

export default withPolycardGallery;
