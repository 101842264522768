import React from 'react';
import PropTypes from 'prop-types';

const PreloadImages = ({ images = [] }) => images.map(image => (
  <link rel="preload" as="image" href={image} />
));

PreloadImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PreloadImages;
