const cartDefaultState = require('./defaultState');
const type = require('./types');

const cartReducer = (
  state = cartDefaultState,
  action,
) => {
  switch (action.type) {
    case type.SET_ADDED_ITEMS: {
      const { items } = action?.payload;

      return {
        ...state,
        addedItems: items,
      };
    }
    case type.CART_ADD_ITEM_SUCCESS: {
      const { id, quantity } = action.payload;
      const addedItemsCopy = [...state.addedItems];
      addedItemsCopy.push({ id, quantity });

      return {
        ...state,
        addedItems: addedItemsCopy,
      };
    }
    case type.CART_UPDATE_ITEM_SUCCESS: {
      const { id, quantity } = action.payload;
      const addedItemsCopy = [...state.addedItems];
      const foundItemObj = addedItemsCopy.find(obj => obj.id === id);
      if (foundItemObj) {
        foundItemObj.quantity = quantity;
      }

      return {
        ...state,
        addedItems: addedItemsCopy,
      };
    }
    case type.CART_DELETE_ITEM_SUCCESS: {
      const { id } = action.payload;
      const addedItemsCopy = [...state.addedItems];
      const itemIndex = addedItemsCopy.findIndex(obj => obj.id === id);
      if (itemIndex !== -1) {
        addedItemsCopy.splice(itemIndex, 1);
      }

      return {
        ...state,
        addedItems: addedItemsCopy,
      };
    }
    default:
      return state;
  }
};

module.exports = cartReducer;
