const React = require('react');
const PropTypes = require('prop-types');
const ClassNames = require('classnames');

const { useRenderContext } = require('../../context');
const { HOME } = require('../../../../utils/constants/sections');
const { default: useLayoutTheme } = require('../../hooks/layoutTheme');
const { default: useHomeTransparency } = require('../../hooks/homeTransparency');

const HomeLayout = ({ section, children, shopName, isCustomHTML = false }) => {
  const { appearance, ccpTemplate } = useRenderContext();

  const layoutTheme = useLayoutTheme();
  const homeTransparency = useHomeTransparency({ isCustomHTML });

  const homeClass = ClassNames(
    'home',
    layoutTheme ? `home--${layoutTheme}` : null,
    {
      'home--custom-html': isCustomHTML,
      'home--with-transparency': homeTransparency,
      'home--with-payment': appearance.getComponentByName('PaymentMethods'),
      'home--with-flash': appearance.getComponentProperty('Header', 'flash'),
      'home--with-banner': appearance.getComponentByName('Banner'),
      'home--with-padding-bottom': !appearance.getComponentByName('ShoppingInfo'),
      'home--padding-top-content': (section !== HOME),
      'home--is-ccp-template': Boolean(ccpTemplate),
    },
  );

  return (
    <div className={homeClass}>
      <h1 className="clipped">{shopName}</h1>
      { children }
    </div>
  );
};

HomeLayout.propTypes = {
  section: PropTypes.string.isRequired,
  shopName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isCustomHTML: PropTypes.bool,
};

module.exports = HomeLayout;
