import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';

import { ProductsGalleryV3 } from '@mshops-components-library/products-gallery';
import { TabWithItems } from '@mshops-components-library/tab-with-items';

import { Wrapper } from './wrapper';
import withTracks from '../../adapters/withTracks';
import { useRenderContext } from '../../pages/home/context';

const ProductsGalleryWrapper = Wrapper(withTracks(ProductsGalleryV3));

const EnhancedProductsGallery = (props) => {
  const defaultAction = { label: '', target: '' };
  const { children, tabs } = props;
  const { withCardAction } = useRenderContext();
  const viewMoreAction = (index) => children[index].props.node.properties.view_more_action;
  const [viewMore, setViewMore] = useState(children.length > 1 ? (
    viewMoreAction(0)) : (defaultAction));
  const { device, isEshops, theme, lowEnd } = useRenderContext();
  const handleClickTab = index => {
    setViewMore(viewMoreAction(index));
  };

  const isLegendOrExplora = theme === 'legend' || theme === 'explorer';
  const propsWithViewMore = {
    ...props,
    viewMore: isLegendOrExplora || device === 'mobile' ? null : viewMore,
    handleClick: isLegendOrExplora ? null : handleClickTab,
    with_card_action: withCardAction,
  };

  return (
    <ProductsGalleryWrapper
      {...props}
      layoutTheme={theme}
      deviceType={device}
      lowEnd={lowEnd}
      isEshops={isEshops}
      componentType="collection_grid"
      tabs={tabs}
    >
      <TabWithItems {...propsWithViewMore} theme={theme} namespace="ui-ms-tab-with-items">
        {children.map((child) => (
          cloneElement(child, { ...child.props, ...props, with_card_action: withCardAction })
        ))}
      </TabWithItems>
    </ProductsGalleryWrapper>
  );
};

EnhancedProductsGallery.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

export default EnhancedProductsGallery;
