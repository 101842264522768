const getCollections = (appearance) => {
  const layoutChildren = appearance?.layout?.children;
  const layoutCarousel = layoutChildren?.find(
    (child) => child.component === 'DynamicCarousels',
  );

  let collections = [];
  if (layoutCarousel) {
    collections = layoutCarousel.children?.filter(
      (child) => child.component === 'Collection',
    );
  } else {
    collections = layoutChildren?.filter(
      (child) => child.component === 'Collection',
    );
  }

  return collections;
};

export { getCollections };
