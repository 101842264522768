const React = require('react');
const PropTypes = require('prop-types');

const Bag = ({
  width = 18,
  height = 18,
  color,
  className = '',
}) => (
  <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <path fill={color} fillRule="evenodd" clipRule="evenodd" d="M13.3726 5.125V4.4975C13.3726 2.08264 11.415 0.125 9.0001 0.125C6.58524 0.125 4.6276 2.08264 4.6276 4.4975V5.125H2.19157L0.801758 17.625H17.1985L15.8069 5.125H13.3726ZM5.8776 4.4975V5.125H12.1226V4.4975C12.1226 2.77299 10.7246 1.375 9.0001 1.375C7.27559 1.375 5.8776 2.77299 5.8776 4.4975ZM3.31029 6.375L2.19844 16.375H15.8017L14.6883 6.375H3.31029Z" fillOpacity="0.9" />
  </svg>
);

Bag.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

module.exports = Bag;
