const React = require('react');
const PropTypes = require('prop-types');

const tracks = require('../utils/tracks');
const { trackEvent } = require('../utils/tracking');
const { useRenderContext } = require('../pages/home/context');

const withTracks = (Base) => {
  const ComponentBase = ({ componentType, ...props }) => {
    const { shopModel } = useRenderContext();
    const sellerId = shopModel?.shop?.id;

    const commonTrack = {
      seller_id: sellerId,
      component_type: componentType,
    };

    const sendTracks = () => {
      trackEvent(tracks.productsComponents, commonTrack);
    };

    return (
      <div
        onMouseEnter={sendTracks}
      >
        <Base {...props} />
      </div>
    );
  };

  ComponentBase.propTypes = {
    componentType: PropTypes.string.isRequired,
  };

  return ComponentBase;
};

export default withTracks;
