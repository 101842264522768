import React from 'react';

import HomeView from '../pages/home/components/HomeView';

const View = props => (<HomeView {...props} />);

export { getServerSideProps, setPageSettings } from '../pages/home/controller';

export const hydrate = 'hydrateRoot';

export default View;
