const { RestClientService, RequestStrategy } = require('@mshops-web-core/rest-client');

class ClientService {
  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  get(url, config, timeout = 3000) {
    return RestClientService.execute(
      new RequestStrategy({
        baseURL: this.baseURL,
        path: url,
        method: 'get',
        config,
        timeout,
        track: { service: 'client', method: 'fetchApi' },
      }),
    );
  }

  post(url, config, timeout = 3000) {
    return RestClientService.execute(
      new RequestStrategy({
        baseURL: this.baseURL,
        path: url,
        method: 'post',
        config,
        timeout,
        track: { service: 'client', method: 'fetchApi' },
      }),
    );
  }

  put(url, config, timeout = 3000) {
    return RestClientService.execute(
      new RequestStrategy({
        baseURL: this.baseURL,
        path: url,
        method: 'put',
        config,
        timeout,
        track: { service: 'client', method: 'fetchApi' },
      }),
    );
  }

  delete(url, config, timeout = 3000) {
    return RestClientService.execute(
      new RequestStrategy({
        baseURL: this.baseURL,
        path: url,
        method: 'delete',
        config,
        timeout,
        track: { service: 'client', method: 'fetchApi' },
      }),
    );
  }
}

module.exports = ClientService;
