import { Snackbar } from '@andes/snackbar';
import React, { createContext, useState } from 'react';
import { node } from 'prop-types';

const SnackbarContext = createContext();

/**
 * This provider is responsible for managing the state of the general snackbar,
 * and providing a function to open it
 */
const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    show: false,
    message: '',
    color: 'red',
    delay: 5000,
    onClose: null,
  });

  const handleClose = () => {
    /**
     * It is necessary to set the state of the snackbar
     * to false to not have conflicts with the next snackbar
     * */
    setSnackbar({
      ...snackbar,
      show: false,
    });

    // Call the onClose function passed as a parameter if it exists
    snackbar.onClose?.();
  };

  const openSnackbar = ({
    message,
    color = 'red', //  Default color
    delay = 5000, // Default delay
    onClose = null,
  }) => {
    // If there is already a snackbar open, close it before opening the new one
    if (snackbar.show) {
      handleClose();
    }

    setSnackbar({
      show: true,
      message,
      color,
      delay,
      onClose,
    });
  };

  return (
    <SnackbarContext.Provider value={openSnackbar}>
      {children}
      <Snackbar
        show={snackbar.show}
        message={snackbar.message}
        color={snackbar.color}
        delay={snackbar.delay}
        onClose={handleClose}
      />
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: node.isRequired,
};

export { SnackbarProvider, SnackbarContext };
