import React from 'react';
import { LazyHydrate } from 'nordic/lazy';

const withLazyLoading = (Component) => {
  const LazyLoadableComponent = (props) => (
    <LazyHydrate whenVisible>
      <Component {...props} />
    </LazyHydrate>
  );

  return LazyLoadableComponent;
};

export default withLazyLoading;
