import React from 'react';
import Script from 'nordic/script';
import PropTypes from 'prop-types';

/**
 * Dynamically renders a `Script` component with the provided attributes and sources.
 * Handles local-to-CDN script mapping and executes initialization logic from the `onload` attribute.
 *
 * Features:
 * - **Local Script Mapping:** Converts local script sources to their corresponding CDN URLs using `sources`.
 * - **Onload Execution:** If the `onload` attribute is present, the `onload` is converted into a callable
 *   function using `new Function(attribs.onload)` and added to the `scripts` array for later execution.
 * - **Argument Validation:** Validates the arguments to ensure they are safe literals (strings or numbers),
 *   preventing code injection attacks.
 * - **Script Rendering:** Returns the updated `Script` component with attributes like `defer` and
 *   `crossOrigin="anonymous"` for compatibility.
 *
 * Props:
 * @param {Object} props - Props for the component.
 * @param {Object} props.attribs - Attributes for the script tag.
 * @param {Object} props.sources - A mapping of local script paths to CDN URLs.
 * @param {Array<Function>} props.scripts - Array to collect and store script initializer functions.
 *
 * Returns:
 * @returns {React.JSX.Element} A `Script` component with updated attributes or an empty fragment.
 */
const CustomScript = (props) => {
  const { attribs, sources, scripts } = props;

  if (attribs.local === 'true') {
    const localSource = attribs.src;
    const cdnSource = sources[localSource];
    const updatedAttribs = { ...attribs, src: cdnSource };
    // eslint-disable-next-line no-new-func
    if (attribs.onload) scripts.push(new Function(attribs.onload));
    return cdnSource ? <Script {...updatedAttribs} defer /> : <></>;
  }
  return <Script {...attribs} defer crossOrigin="anonymous" />;
};

CustomScript.propTypes = {
  attribs: PropTypes.shape({
    src: PropTypes.string,
    onload: PropTypes.string,
    local: PropTypes.string,
  }),
  sources: PropTypes.shape({}),
  scripts: PropTypes.array,
};

export default CustomScript;
