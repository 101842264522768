const isFirstComponent = (components = [], appearance) => {
  const isFirstElement = components.some(componentName => {
    const component = appearance.getComponentByName(componentName);
    const position = component && appearance.getLayoutComponentIndex(component.id);
    return position === 0;
  });
  return isFirstElement;
};

module.exports = { isFirstComponent };
