/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardAction from '@mshops-components-library/card-action';

const FALLBACK_WAITING_TIME_MS = 3000;

const CardActionCustomWrapper = ({
  item,
  cart_info: cardAction,
  addItem,
  updateItem,
  deleteItem,
  deviceType,
  onlyWebCheckout,
  trackingData,
  trackEvent,
  cartItemQuantity,
}) => {
  const {
    id,
    with_card_action: withCardAction,
    variations_data: variationsData,
    sale_terms: saleTerms,
    available_quantity: availableQuantity,
    variations_qty: variationsQty,
    relevant,
    onClickCta,
    permalink: itemLink,
    product_cart_info: {
      is_cart_eligible: isCartEligible,
      checkout_target_mobile: checkoutTargetMobile,
      checkout_target: checkoutTarget,
    },
  } = item;
  const [itemQuantity, setItemQuantity] = useState(cartItemQuantity);
  const [updateAction, setUpdateAction] = useState(
    cartItemQuantity ? 'update' : 'add',
  );
  const [initialValue, setInitial] = useState(true);
  const [quantityUpdateId, setQuantityUpdateId] = useState(null);

  const variation = variationsData?.length > 0 ? variationsData[0]?.id : '';
  const {
    purchase_min_quantity: purchaseMinQuantity,
    purchase_max_quantity: purchaseMaxQuantity,
  } = saleTerms || {};
  const hasMoreVariations = variationsQty && variationsQty > 1;

  const handleCtaClick = (event, redirectTo, doNotTrack) => {
    event.stopPropagation();

    if (onClickCta) {
      onClickCta();
    } else if (redirectTo) {
      if (trackEvent && trackingData && !doNotTrack) {
        trackEvent(trackingData);
      }

      window.location.assign(redirectTo);
    }
  };

  const addToCart = (minQuantity) => {
    if (cartItemQuantity > 0) {
      setUpdateAction('update');
    } else {
      setUpdateAction('add');
    }

    setItemQuantity(minQuantity);
  };

  // eslint-disable-next-line consistent-return
  const shouldAddToCart = (event) => {
    event.preventDefault();

    if (hasMoreVariations || relevant) {
      handleCtaClick(event, itemLink);
      // eslint-disable-next-line no-negated-condition
    } else if (!isCartEligible) {
      if (onlyWebCheckout) {
        return handleCtaClick(event, checkoutTarget);
      }

      handleCtaClick(event, checkoutTargetMobile);

      if (checkoutTargetMobile !== checkoutTarget) {
        setTimeout(() => {
          handleCtaClick(event, checkoutTarget, true);
        }, FALLBACK_WAITING_TIME_MS);
      }
    } else {
      if (trackEvent && trackingData) {
        trackEvent(trackingData);
      }

      addToCart(purchaseMinQuantity ?? 1);
    }
  };

  const getMaxPurchaseQuantity = () => (purchaseMaxQuantity !== null && purchaseMaxQuantity <= availableQuantity
    ? purchaseMaxQuantity
    : availableQuantity);

  const handleQuantityIncrement = () => {
    const newQuantity = itemQuantity + 1;

    return setItemQuantity(newQuantity);
  };

  const handleQuantityDecrement = () => {
    const newQuantity = itemQuantity - 1;

    if (
      newQuantity === 0
      || (purchaseMinQuantity && newQuantity < purchaseMinQuantity)
    ) {
      setUpdateAction('delete');

      return setItemQuantity(0);
    }

    setUpdateAction('update');

    return setItemQuantity(newQuantity);
  };

  useEffect(() => {
    if (initialValue) {
      setInitial(false);

      return;
    }

    if (quantityUpdateId !== null) {
      clearTimeout(quantityUpdateId);
    }

    const timeoutId = setTimeout(async () => {
      let cartPromise = updateAction === 'update' ? updateItem : addItem;

      if (itemQuantity === 0) {
        if (updateAction === 'add') {
          return;
        }

        cartPromise = deleteItem;
      }

      const success = await cartPromise(id, itemQuantity, variation);

      if (!success) {
        setInitial(true);
        setItemQuantity(cartItemQuantity || 0);
      }

      setUpdateAction(itemQuantity > 0 ? 'update' : 'add');

      clearTimeout(quantityUpdateId);
    }, 700);

    setQuantityUpdateId(timeoutId);
  }, [itemQuantity]);

  return (
    <>
      {withCardAction && (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="ui-ms-tab-with-items__card-action"
        >
          <CardAction
            layout="column"
            onAddToCart={shouldAddToCart}
            itemQuantity={itemQuantity}
            purchaseMaxQuantity={getMaxPurchaseQuantity()}
            incrementQuantity={handleQuantityIncrement}
            decrementQuantity={handleQuantityDecrement}
            hasMoreVariations={hasMoreVariations}
            isCartEligible={isCartEligible}
            deviceType={deviceType}
            relevant={relevant}
            {...cardAction}
          />
        </div>
      )}
    </>
  );
};

CardActionCustomWrapper.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    with_card_action: PropTypes.bool.isRequired,
    sale_terms: PropTypes.shape({}),
    available_quantity: PropTypes.number.isRequired,
    variations_qty: PropTypes.number,
    relevant: PropTypes.bool,
    permalink: PropTypes.string,
    variations_data: PropTypes.arrayOf(PropTypes.shape({})),
    product_cart_info: PropTypes.shape({
      is_cart_eligible: PropTypes.bool.isRequired,
      checkout_target_mobile: PropTypes.string,
      checkout_target: PropTypes.string,
    }),
    price: PropTypes.shape({
      currency_id: PropTypes.string,
      value: PropTypes.number,
    }),
    brand_info: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    onClickCta: PropTypes.func,
  }),
  cart_info: PropTypes.shape({}),
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  addItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  onlyWebCheckout: PropTypes.bool,
  trackingData: PropTypes.shape({}),
  trackEvent: PropTypes.func,
  cartItemQuantity: PropTypes.number.isRequired,
};

export default CardActionCustomWrapper;
