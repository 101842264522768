import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Close16 from '@andes/icons/Close16';
import { Button } from '@andes/button';
import { Bag } from '../../../../components/icon';

import wordings from './wordings';
import ClientService from '../../../../../services/client';
import { useRenderContext } from '../../context';

const PurchaseAccess = ({ i18n, accessPosition }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [url, setUrl] = useState('');
  const { title, label } = wordings(i18n);

  const hideAccess = () => {
    setIsVisible(false);
  };

  const {
    restClient,
  } = useRenderContext();

  const fetchActivePurchase = async () => {
    try {
      const request = new ClientService(restClient.baseURL);
      const { data } = await request.post('purchases/active-purchase');

      if (data.purchaseUrl) {
        setUrl(data.purchaseUrl);
        setIsVisible(true);
      }
    } catch {
      // In case of error do nothing
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const modalExists = window.document.querySelector('.ui-ms-newsletter-modal');
      if (!modalExists) {
        fetchActivePurchase();
        clearInterval(intervalId);
      }
    }, 1000);
  }, []);

  if (!accessPosition || !isVisible) {
    return null;
  }

  return (
    <div className="ui-ms-purchase-access" style={{ top: accessPosition }}>
      <div className="ui-ms-purchase-access__wrapper">
        <div className="ui-ms-purchase-access__icon-content">
          <Bag className="ui-ms-purchase-access__icon" />
        </div>
        <div className="ui-ms-purchase-access__content">
          <p className="ui-ms-purchase-access__title">{title}</p>
          <Button
            href={url}
            hierarchy="transparent"
            size="small"
            className="ui-ms-purchase-access__button"
          >
            {label}
          </Button>
        </div>
      </div>
      <Button
        hierarchy="transparent"
        size="small"
        className="ui-ms-purchase-access__close-button"
        onClick={hideAccess}
        type="button"
      >
        <Close16 className="ui-ms-purchase-access__close-icon" />
      </Button>
    </div>
  );
};

PurchaseAccess.propTypes = {
  accessPosition: PropTypes.number.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

export default PurchaseAccess;
