const freeShippingDefaultState = require('./defaultState');
const type = require('./types');

const freeShippingReducer = (
  state = freeShippingDefaultState,
  action,
) => {
  switch (action.type) {
    case type.SET_FREE_SHIPPING: {
      const { freeShippingBar } = action.payload;

      return {
        ...state,
        freeShippingBar,
      };
    }
    default:
      return state;
  }
};

module.exports = freeShippingReducer;
