/* On adding one element here you must add it on mshops-web-myml-editor */
const CHILDREN_TO_IGNORE = ['debug-info', 'newsletter-modal_1'];

const removeUnnecessaryChildren = appearance => {
  let elementsRemoved = null;
  if (appearance.layout) {
    elementsRemoved = appearance.layout.children.filter(children => CHILDREN_TO_IGNORE.includes(children.id));
    appearance.layout.children = (
      appearance.layout.children.filter(children => !CHILDREN_TO_IGNORE.includes(children.id))
    );
  }

  return { appearanceChildrenRemoved: appearance, elementsRemoved };
};

module.exports = removeUnnecessaryChildren;
