const React = require('react');

const Eye = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
    <g fill="none" fillRule="evenodd" stroke="#3483FA" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(1 1)">
      <path d="M0 6.545S3.331 0 9.16 0c5.83 0 9.161 6.545 9.161 6.545s-3.33 6.546-9.16 6.546S0 6.545 0 6.545z" />
      <ellipse cx="9.161" cy="6.545" rx="2.498" ry="2.455" />
    </g>
  </svg>
);

module.exports = Eye;
