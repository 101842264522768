const React = require('react');
const PropTypes = require('prop-types');

const { ItemDesktop } = require('@mshops-components-library/item');
const { useRenderContext } = require('../../pages/home/context');
const { getMaxItems, getItemsPerRow } = require('./utils/validations');

function CarouselWrapper(RecommendationsBase) {
  function CarouselBase(props) {
    const { shopType, device, isEshops, currency, restClient, features } = useRenderContext();
    const {
      items,
      categories,
      maxItemsMobile,
      itemsPerRowMobile,
      maxItems,
      itemsPerRow,
      i18n,
    } = props;

    const maxItemLocal = getMaxItems(shopType, device, maxItems, maxItemsMobile);
    const itemsPerRowLocal = getItemsPerRow(shopType, device, itemsPerRow, itemsPerRowMobile);

    const shippingTitle = i18n.gettext('Envío gratis');
    const ftNewComponentsV2 = features?.includes('ft-new-components-v2');

    return Boolean(items?.length || categories?.length) && (
      <div className="mshops-recommendations-wrapper">
        <RecommendationsBase
          metadata={currency}
          restClient={restClient}
          type="recommendations"
          {...props}
          shippingTitle={shippingTitle}
          eshopType={shopType}
          maxItems={maxItemLocal}
          itemsPerRow={itemsPerRowLocal}
          withoutTabsCategory={isEshops}
          ftNewComponentsV2={ftNewComponentsV2}
        />
      </div>
    );
  }

  CarouselBase.propTypes = {
    i18n: PropTypes.shape({ gettext: PropTypes.func.isRequired }),
    items: PropTypes.arrayOf(PropTypes.shape(ItemDesktop.itemPropTypes)).isRequired,
    title: PropTypes.string,
    maxItemsMobile: PropTypes.number,
    itemsPerRowMobile: PropTypes.number,
    maxItems: PropTypes.number.isRequired,
    itemsPerRow: PropTypes.number.isRequired,
  };

  return CarouselBase;
}

module.exports = CarouselWrapper;
