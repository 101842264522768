import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import useScriptInitializer from '../../hooks/useCustomScriptsInitializer';
import useCustomizationParser from '../../hooks/useCustomizationParser';

/**
 * Renders a custom component based on the `customization.html` content, ensuring proper execution
 * of scripts, stable visuals, and seamless user experience. The component dynamically converts raw
 * HTML into React components and manages visibility to avoid rendering incomplete or broken states
 * during script initialization.
 *
 * Features:
 * 1. **Script Management:**<br>
 *   Utilizes the `useScriptInitializer` hook to handle script execution and manage the
 *   necessary states and DOM interactions.
 * 2. **HTML Parsing:**
 *   - Transforms raw HTML into React components using the `useCustomizationParser` hook.
 *   - Replaces `<script>` tags with `Script` components from Nordic.
 *   - Substitutes "add to cart" buttons with the React component `CardActionCustomWrapper`.
 * 3. **Visibility Control:**<br>
 *   Ensures the component is hidden (`visibility: "hidden"`) until scripts are fully executed
 *   and the component is stable, preventing broken or incomplete states.
 * 4. **State Synchronization:**
 *   - A `useEffect` hook re-executes scripts and updates the `scriptsLoaded` state whenever
 *     `customization` changes.
 *   - A `useMemo` hook resets `scriptsLoaded` to `false` before re-rendering, ensuring proper
 *     script reinitialization.
 *
 * Props:
 * @param {Object} customization - The customization object containing raw HTML and script content.
 * @param {Object} props - Additional props for the component, including `customization` and `tabs`.
 *
 * Returns:
 * @returns {Element} - A `div` element containing dynamically parsed and rendered HTML content.
 */

const CustomComponent = ({ customization, ...props }) => {
  const { scripts, executeScripts, scriptsLoaded, setScriptsLoaded } = useScriptInitializer();
  const { domNodes } = useCustomizationParser(
    customization,
    scripts,
    props.getItem,
    props.withCardAction,
    props.trackingInfo,
    props.props,
  );

  useEffect(() => {
    executeScripts();
    setScriptsLoaded(true);
  }, [customization]);
  useMemo(() => {
    setScriptsLoaded(false);
  }, [customization]);

  const skeletonStyle = {
    visibility: 'hidden',
    maxHeight: '50vh',
    maxWidth: '100vw',
    overflow: 'hidden',
  };

  return <div style={scriptsLoaded ? undefined : skeletonStyle}>{domNodes}</div>;
};

CustomComponent.propTypes = {
  customization: PropTypes.shape({
    html: PropTypes.string.isRequired,
    javascript: PropTypes.shape({}).isRequired,
  }).isRequired,
  scripts: PropTypes.arrayOf(PropTypes.object),
  getItem: PropTypes.func,
  withCardAction: PropTypes.bool,
  trackingInfo: PropTypes.func,
  props: PropTypes.object,
};

export default CustomComponent;
