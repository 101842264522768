const React = require('react');
const PropTypes = require('prop-types');

const Slide = ({
  className = '',
  width = 18,
  height = 18,
  color = '#3483FA',
}) => (
  <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <path fill={color} fillRule="nonzero" d="M7 5v8l5-4-5-4zm9-5H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 16H2V2h14v14z" />
  </svg>
);

Slide.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

module.exports = Slide;
