import React from 'react';
import PropTypes from 'prop-types';

import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';

import { useRenderContext } from '../../pages/home/context';

function Layout({ children }) {
  const { fetching } = useRenderContext();
  return (
    <div>
      { fetching
      && <ProgressIndicatorCircular
        modifier="fullscreen"
        size="xlarge"
      /> }
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Layout;
